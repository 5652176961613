body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.none {
  height: 0;
  width: 0;
  opacity: 0;
}

.file__wrapper {
  width: 110px;
  cursor: pointer;
  position: relative;
  height: calc(56px + 21px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.file__icon {
  padding: 16px;
  border: 1px solid #bfbfbf
}

.file__wrapper-filename {
  white-space: nowrap;
  width: 110px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: .9;
}

.file__delete{
  position: absolute;
  right: -8px;
  top: -15px;
  cursor: pointer;
  display: none;
}

.file__wrapper:hover > .file__delete {
  display: flex;
  z-index: 10;
}

.file__delete:hover > div {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.5);
  background-color: white;
  border-radius: 12px;
  display: flex;
}

.file__delete:hover {
  padding: 16px;
  margin: -16px;
}

.file__wrapper:hover > .file__wrapper-filename {
  white-space: pre-line;
  background-color: white;
  transition: all .05s, ;
  position: absolute;
  max-height: 126px;
  overflow-y: auto;
  opacity: 1;
  padding: 8px;
  margin: -8px;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}

.uploadfile__wrapper{
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition: .3s ;
  cursor: pointer;
  width: 110px;
  height: calc(56px + 21px);
}

.uploadfile__wrapper > div{
  border: 1px solid #bfbfbf;
  transition: .3s ;
  cursor: pointer;
  padding: 16px;
}

.uploadfile__wrapper:hover > div{
  border-color: #40a9ff;
  transition: .3s;
}

.success_blink{
  transition: .3s;
  background-color: lightgreen;
}

.error_blink{
  transition: .3s;
  background-color: lightcoral;
}

.notification__inline-create{
  width: 100%;
  padding: 16px;
  height: 100%;
  opacity: .3;
  transition: .3s;
  border: 1px solid rgb(240 240 240)
}

.notification__inline-create:hover{
  opacity: 1;
  transition: .3s;
}

.logo{
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0
}