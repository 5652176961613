
@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-BoldIt.eot');
    src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
        url('ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-BoldIt.woff2') format('woff2'),
        url('ProximaNova-BoldIt.woff') format('woff'),
        url('ProximaNova-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Bold.eot');
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff'),
        url('ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-RegularIt.eot');
    src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
        url('ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-RegularIt.woff2') format('woff2'),
        url('ProximaNova-RegularIt.woff') format('woff'),
        url('ProximaNova-RegularIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Regular.eot');
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
